export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  env: 'dev',
  APIUrl: 'https://thesparktech.sparktechdev.org/api/en-in/',
  APIMainUrl: 'https://thesparktech.sparktechdev.org/api/en-in/',
  orgURL: 'https://thesparktech.sparktechdev.org',
  domain: 'sparktechdev.org',
  myaccountDomain: 'https://myaccount.sparktechdev.org',
  passphrase: '{<^All|//|eB@pp$^>)&[~$T#25218~]',
  firebaseConfig: {
    apiKey: "AIzaSyCC74TVyn0gEwBS8KC7wL66hnlRnH3U_5A",
    authDomain: "webtest-1b931.firebaseapp.com",
    projectId: "webtest-1b931",
    storageBucket: "webtest-1b931.appspot.com",
    messagingSenderId: "360830017288",
    appId: "1:360830017288:web:0334c8e7dc63670dabd6bd",
    measurementId: "G-JHZC8ERRT1"
  },
  isDebug: true
};
